@media screen and (max-width:700px) {
    .header{
        border-bottom: 1px solid grey;
        width: 100%;
        overflow: hidden;
    }
    .headerList{
        /* position: relative; */
        background-color: white;
    }
    .listOne{
        z-index: -2;
        top: -500px;
        left: 0;
        /* right: -500px; */
        /* left: 0; */
        width: 100%;
        /* height: 0px; */
        padding: 50px;
        background-color: white;
        position: absolute; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out;
    }
    .menuOpen{
        top: 60px;
        z-index: 1;
        /* right: 0; */
        width: 100%;
        /* height: 0px; */
        padding: 50px;
        background-color: white;
        position: absolute; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out;
    }
    
}