.Articlebutton:hover .ArticlebuttonName{
    border-bottom: 2px solid #eb6500;
    transition: all 0.2s ease-in-out;
    /* color: blueviolet; */
}
/* .ArticlebuttonName
.ArticlebuttonBox */

.Articlebutton:hover .ArticlebuttonBox{
    background-color: transparent;
    border: 2px solid #eb6500;
    color: black;
    transition: all 0.2s ease-in-out;
}